import Dropdown from "../../widgets/form/select/Dropdown.vue";

export default {
	data: function() {
		return {
      options: [
        {'name': 'По наличию по возрастанию', sort: 'stock', direction: 1},
        {'name': 'По наличию по убыванию', sort: 'stock', direction: -1},
        {'name': 'По цене по возрастанию', sort: 'price', direction: 1},
        {'name': 'По цене по убыванию', sort: 'price', direction: -1},
        {'name': 'По популярности по возрастанию', sort: 'popularity', direction: 1},
        {'name': 'По популярности по убыванию', sort: 'popularity', direction: -1},
        {'name': 'По названию по возрастанию', sort: 'name', direction: 1},
        {'name': 'По названию по убыванию', sort: 'name', direction: -1},
      ],
      selectedOption: {'name': 'По наличию по возрастанию', sort: 'stock', direction: 1},
			sort: 'stock',
			direction: 1,
		}
	},
	components: {
    Dropdown,
	},
  methods: {
		doChange(sort) {
      this.direction = this.sort !== sort || this.direction < 0 ? 1 : -1;
			this.sort = sort;
      this.selectedOption = this.options.find((o) => o.sort === this.sort && o.direction === this.direction)
			this.$emit('change', this.sort, this.direction);
		},
    updateOption(value) {
      this.sort = value.sort;
      this.direction = value.direction;
      this.$emit('change', this.sort, this.direction);
    }
	}
}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isActive
    ? _c("div", { staticClass: "menu" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "menu__wrapper" }, [
            _c("div", { staticClass: "menu__main" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col col-12 col-sm-12 col-md-4 col-lg-4" },
                  [
                    _c("div", { staticClass: "menu-root-items theme--grey" }, [
                      _c(
                        "ul",
                        _vm._l(_vm.items.level1, function (item, index) {
                          return !item.type
                            ? _c(
                                "li",
                                {
                                  class: {
                                    active: item.id == _vm.currentLevel1,
                                  },
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "menu-link-w-icon",
                                      class: {
                                        "menu-link--with-arrow": !item.noarrow,
                                      },
                                      attrs: { href: "/cat/g/" + item.id },
                                      on: {
                                        mouseover: function ($event) {
                                          return _vm.mouseover(1, item.id)
                                        },
                                        mouseleave: _vm.mouseleave,
                                      },
                                    },
                                    [
                                      _vm._v(_vm._s(item.title)),
                                      _c("span", {
                                        staticClass: "menu-link-arrow",
                                      }),
                                    ]
                                  ),
                                ]
                              )
                            : _c("li", { staticClass: "menu-item-sep" })
                        }),
                        0
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "col col-12 col-sm-12 col-md-4 col-lg-4 d-none d-md-block",
                  },
                  [
                    _c("div", { staticClass: "menu-col1" }, [
                      _c(
                        "ul",
                        _vm._l(_vm.items.level2, function (item) {
                          return item.parent_id == _vm.currentLevel1
                            ? _c(
                                "li",
                                {
                                  class: {
                                    active: item.id == _vm.currentLevel2,
                                  },
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "menu-link",
                                      class: {
                                        "menu-link--with-arrow": !item.noarrow,
                                      },
                                      attrs: { href: "/cat/g/" + item.id },
                                      on: {
                                        mouseover: function ($event) {
                                          return _vm.mouseover(2, item.id)
                                        },
                                        mouseleave: _vm.mouseleave,
                                      },
                                    },
                                    [
                                      _vm._v(_vm._s(item.title)),
                                      _c("span", {
                                        staticClass: "menu-link-arrow",
                                      }),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e()
                        }),
                        0
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "col col-12 col-sm-12 col-md-4 col-lg-4 d-none d-md-block",
                  },
                  [
                    _c("div", { staticClass: "menu-col1" }, [
                      _c(
                        "ul",
                        _vm._l(_vm.items.level3, function (item) {
                          return item.parent_id == _vm.currentLevel2
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "menu-link",
                                    attrs: { href: "/cat/g/" + item.id },
                                  },
                                  [_vm._v(_vm._s(item.title))]
                                ),
                              ])
                            : _vm._e()
                        }),
                        0
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
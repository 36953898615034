<template>
	<div class="f-group">
		<a href="javascript:void(0);" class="f-group-header" v-html="item.title" v-bind:class="{'active': isActive}" @click.prevent="headerClick"></a>

		<div class="f-group-content" v-bind:class="{'active': isActive}">
			<!-- <div class="f-group-content__inner" v-if="item.type == 'price'">
				<PriceRange></PriceRange>
			</div> -->

			<div class="f-group-content__inner" v-if="item.type == 'range'">
				<Range :min="item.min" :max="item.max" @change="doChange"></Range>
			</div>

			<div class="f-group-content__inner" v-if="item.type == 'checkboxes'">
				<Checkboxes :items="item.items" @change="doChange"></Checkboxes>
			</div>

		</div>
	</div>
</template>

<script>
import PriceRange from '../../widgets/price-range/PriceRange.vue';
import Range from '../../widgets/range/Range.vue';
import Checkboxes from '../../widgets/checkboxes/Checkboxes.vue';

export default {
	data: function() {
		return {
			isActive: false,
			value: null,
		}
	},
	props: {
		item: { type: Object }
	},
	mounted: function() {
		this.isActive = this.item.expanded;
	},
	components: {
		PriceRange,
		Range,
		Checkboxes,
	},
	methods: {
		headerClick: function(){
			this.isActive = !this.isActive;
		},
		doChange: function(e) {
			console.log(arguments)
			this.$emit('change', {item: this.item.id, value: e.value});
		}
	}
}
</script>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isActive,
          expression: "isActive",
        },
      ],
      staticClass: "popover cart-popover",
    },
    [
      _c("div", { staticClass: "cart-popover__title h3" }, [
        _vm._v("В корзине " + _vm._s(_vm.items.length) + "\n\t\t"),
        _vm.items.length % 10 == 1 && _vm.items.length % 100 != 11
          ? _c("span", [_vm._v("товар")])
          : 2 <= _vm.items.length % 10 &&
            _vm.items.length % 10 <= 4 &&
            (_vm.items.length % 100 < 10 || _vm.items.length % 100 >= 20)
          ? _c("span", [_vm._v("товара")])
          : _c("span", [_vm._v("товаров")]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "cart-popover__products" },
        _vm._l(_vm.items, function (p, index) {
          return _c(
            "div",
            {
              key: "cart-popover-product" + index,
              staticClass: "popover-cart-product",
            },
            [
              _c("div", { staticClass: "popover-cart-product__img" }, [
                _c("img", { attrs: { src: p.photo } }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "popover-cart-product__description" }, [
                _c("div", { staticClass: "popover-cart-product__name" }, [
                  _c("a", { attrs: { href: "#" } }, [_vm._v(_vm._s(p.label))]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "popover-cart-product__price" }, [
                  _c(
                    "span",
                    { staticClass: "popover-cart-product__price-current" },
                    [_vm._v(_vm._s(_vm.priceFormat(p.price)) + " руб.")]
                  ),
                  p.discount
                    ? _c(
                        "span",
                        { staticClass: "popover-cart-product__price-old" },
                        [_vm._v(_vm._s(p.priceOld) + " руб.")]
                      )
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "popover-cart-product__qty" },
                [
                  _c("Quantity", {
                    attrs: { itemId: p.item_id, initialValue: p.quantity },
                    on: { change: _vm.doChangeItem },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "popover-cart-product__remove" }, [
                _c(
                  "a",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.doRemoveItem(p.item_id)
                      },
                    },
                  },
                  [_c("span", { staticClass: "icon icon-remove-black" })]
                ),
              ]),
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "cart-popover-bottom" }, [
        _c("div", { staticClass: "cart-popover-bottom__total" }, [
          _c("div", { staticClass: "cart-popover-bottom__total-caption" }, [
            _vm._v("Итого"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "cart-popover-bottom__total-value" }, [
            _vm._v(_vm._s(_vm.priceFormat(_vm.total)) + " руб."),
          ]),
        ]),
        _vm._v(" "),
        _vm._m(0),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cart-popover-bottom__btn" }, [
      _c("a", { staticClass: "btn", attrs: { href: "/order/edit" } }, [
        _vm._v("Перейти в корзину"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
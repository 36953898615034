import Product from '../../product.vue';
import Vue from 'vue';

export default {
	components: {
		Product
	},
	computed: {},
	props: {
		items: {
			type: Array
		}
	}	
}
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "f-group" }, [
    _c("a", {
      staticClass: "f-group-header",
      class: { active: _vm.isActive },
      attrs: { href: "javascript:void(0);" },
      domProps: { innerHTML: _vm._s(_vm.item.title) },
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.headerClick.apply(null, arguments)
        },
      },
    }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "f-group-content", class: { active: _vm.isActive } },
      [
        _vm.item.type == "range"
          ? _c(
              "div",
              { staticClass: "f-group-content__inner" },
              [
                _c("Range", {
                  attrs: { min: _vm.item.min, max: _vm.item.max },
                  on: { change: _vm.doChange },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.item.type == "checkboxes"
          ? _c(
              "div",
              { staticClass: "f-group-content__inner" },
              [
                _c("Checkboxes", {
                  attrs: { items: _vm.item.items },
                  on: { change: _vm.doChange },
                }),
              ],
              1
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
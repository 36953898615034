var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "products-grid" }, [
    _c("div", { staticClass: "eq-container" }, [
      _c(
        "div",
        { staticClass: "products-grid__wrapper row" },
        _vm._l(_vm.items, function (product) {
          return _c(
            "div",
            {
              key: product.id,
              staticClass:
                "products-grid__item col col-12 col-sm-12 col-md-6 col-lg-4",
              attrs: { product: product },
            },
            [_c("Product", { attrs: { product: product } })],
            1
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Quantity from '../quantity/Quantity.vue';

export default {
	data: function(){
		return {}
	},
	props: {
		total: { type: Number },
		items: { type: Array },
		isActive: { type: Boolean, default: false },
		closeHandler: {	type: Function }
	},
	components: {
		Quantity
	},
	methods: {
		doChangeItem(event) {
			const app = Yarok.getApp();
			app.catalogUpdateCart(event.itemId, event.value);
		},
		doRemoveItem(itemId) {
			const app = Yarok.getApp();
			app.catalogUpdateCart(itemId, 0);
		},
		priceFormat(value) {
			const formatter = new Intl.NumberFormat('ru-RU', {});
			return formatter.format(value);
		},
		/*clickHandler(event) {
			const { target } = event;
			const { $el } = this;

			if (!$el.contains(target)) {
			  this.closeHandler();
			}
		}*/
	}
	/*mounted() {
		document.addEventListener('click', this.clickHandler);
	},

	beforeDestroy() {
		document.removeEventListener('click', this.clickHandler);
	}*/
}

export default {
  methods: {
    showSubscriptionModal () {
      this.$modal.show('subscription-modal');
    },
    hideSubscriptionModal () {
      this.$modal.hide('subscription-modal');
    },
  }
}

export default {
	data: function(){
		return {
			value: [],
		}
	},
	props: {
		items: { type: Array },
	},
	methods: {
		doChange: function(evt) {
			console.log(arguments);
			this.$emit('change', this);
		}
	}
}
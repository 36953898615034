var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "products-section offers" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "heading" }, [
        _c("div", { staticClass: "heading__l" }, [
          _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "heading__r" }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "products-carousel-outer" },
        [
          _c(
            "equalizer",
            {
              attrs: {
                classes: ["product"],
                config: {
                  0: undefined,
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "products-carousel" },
                [
                  _c(
                    "VueSlickCarousel",
                    _vm._b({}, "VueSlickCarousel", _vm.settings, false),
                    _vm._l(_vm.items, function (product) {
                      return _c(
                        "div",
                        {
                          key: product.id,
                          staticClass: "products-carousel__item",
                          attrs: { product: product },
                        },
                        [_c("Product", { attrs: { product: product } })],
                        1
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "benefits" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "benefits__whitebox" }, [
        _vm.$vssWidth > 767
          ? _c("div", { staticClass: "benefits-list" }, [
              _c(
                "div",
                { staticClass: "benefits-list__inner" },
                _vm._l(_vm.items, function (item) {
                  return _c("div", { staticClass: "benefit-card" }, [
                    _c("div", { staticClass: "benefit-card__icon" }, [
                      _c("img", { attrs: { src: item.icon, alt: "" } }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "benefit-card__title" }, [
                      _vm._v(_vm._s(item.title)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "benefit-card__descr" }, [
                      _vm._v(_vm._s(item.descr)),
                    ]),
                  ])
                }),
                0
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        false
          ? _c("div", { staticClass: "benefits-detail-view" }, [
              _c("a", {
                staticClass: "benefits-detail-view__close",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                  },
                },
              }),
              _vm._v(" "),
              _vm._m(0),
              _vm._v(" "),
              _vm._m(1),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.$vssWidth <= 767
          ? _c("div", { staticClass: "benefits-carousel" }, [
              _c(
                "div",
                { staticClass: "benefits-carousel__inner" },
                [
                  _c(
                    "VueSlickCarousel",
                    _vm._b({}, "VueSlickCarousel", _vm.settings, false),
                    _vm._l(_vm.items, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: "benefit-" + index,
                          staticClass: "benefit-card",
                        },
                        [
                          _c("div", { staticClass: "benefit-card__icon" }, [
                            _c("img", { attrs: { src: item.icon, alt: "" } }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "benefit-card__title" }, [
                            _vm._v(_vm._s(item.title)),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "benefit-card__descr" }, [
                            _vm._v(_vm._s(item.descr)),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "benefits-detail-card" }, [
      _c("div", { staticClass: "benefits-detail-card__icon" }, [
        _c("img", { attrs: { src: "/media/img/benefit-md-1.png", alt: "" } }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "benefits-detail-card__main" }, [
        _c("div", { staticClass: "benefits-detail-card__title h3" }, [
          _vm._v("Нестандартные заказы"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "benefits-detail-card__descr" }, [
          _vm._v("Подбираем заказы под ваши запросы и пожелания:"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "benefits-detail-card__additional-descr" }, [
          _c("ul", [
            _c("li", [_vm._v("работаем с нестандартными заказами;")]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "не ограничиваемся ассортиментом, представленным в каталоге;"
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "по запросу, наши менеджеры подберут товар, удовлетворяющий именно Вашим пожеланиям."
              ),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "nav-arrows-inline" }, [
      _c("a", {
        staticClass: "nav-arrow-inline prev",
        attrs: { href: "javascript:void(0);" },
      }),
      _c("a", {
        staticClass: "nav-arrow-inline next",
        attrs: { href: "javascript:void(0);" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
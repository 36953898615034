var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tabs-component" }, [
    _c(
      "ul",
      { staticClass: "tabs-component-tabs", attrs: { role: "tablist" } },
      _vm._l(_vm.tabs, function (tab, i) {
        return _c(
          "li",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: tab.isVisible,
                expression: "tab.isVisible",
              },
            ],
            key: i,
            staticClass: "tabs-component-tab",
            class: { "is-active": tab.isActive, "is-disabled": tab.isDisabled },
            attrs: { role: "presentation" },
          },
          [
            _c("a", {
              staticClass: "tabs-component-tab-a",
              attrs: {
                "aria-controls": tab.hash,
                "aria-selected": tab.isActive,
                href: tab.hash,
                role: "tab",
              },
              domProps: { innerHTML: _vm._s(tab.nameText) },
              on: {
                click: function ($event) {
                  return _vm.selectTab(tab.hash, $event)
                },
              },
            }),
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "tabs-component-panels" }, [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
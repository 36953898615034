export default {
	data() {
		return {
			value: this.initialValue
		}
	},
	props: {
		itemId: { type: String },
		initialValue: { type: Number, default: 1 },
	},
	methods: {
		plus() {
			this.value++;
			this.emitChange();
		},
		minus() {
			if (this.value > 1) {
				this.value--;
			} else {
				this.value = 1;
			}
			this.emitChange();
		},
		emitChange() {
			this.$emit('change', {itemId: this.itemId, value: this.value})
		}
	}
}
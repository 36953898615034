var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "filter" },
    _vm._l(_vm.filters, function (item, index) {
      return _c("FilterGroup", {
        key: item.id,
        attrs: { item: item },
        on: { change: _vm.doChange },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
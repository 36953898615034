// /**
//  * Components docs: 
//  * https://nightcatsama.github.io/vue-slider-component/
//  */

// import VueSlider from 'vue-slider-component'
// import 'vue-slider-component/theme/default.css'

export default {
	data: function(){
		return {
			value: [null, null],
		}
	},
	// components: {
	// 	VueSlider
	// },
	props: {
		min: { type: Number },
		max: { type: Number },
	},
	methods: {
		doChange: function(evt) {
			console.log(arguments);
			this.$emit('change', this);
		}
	}
}
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "price-range" }, [
    _c("div", { staticClass: "price-range__inputs" }, [
      _c("div", { staticClass: "price-range__min" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.priceRange[0],
              expression: "priceRange[0]",
            },
          ],
          staticClass: "form-control",
          attrs: {
            type: "text",
            placeholder: "",
            value: "307",
            name: "price_min",
          },
          domProps: { value: _vm.priceRange[0] },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.priceRange, 0, $event.target.value)
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "price-range__sep" }),
      _vm._v(" "),
      _c("div", { staticClass: "price-range__max" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.priceRange[1],
              expression: "priceRange[1]",
            },
          ],
          staticClass: "form-control",
          attrs: {
            type: "text",
            placeholder: "",
            value: "2308",
            name: "price_max",
          },
          domProps: { value: _vm.priceRange[1] },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.priceRange, 1, $event.target.value)
            },
          },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
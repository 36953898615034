var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "btn-group" }, [
    _vm.selectedOption.name !== undefined
      ? _c(
          "li",
          {
            staticClass: "dropdown-toggle",
            on: {
              click: function ($event) {
                return _vm.toggleMenu()
              },
            },
          },
          [
            _vm._v("\n      " + _vm._s(_vm.selectedOption.name) + "\n      "),
            _c("span", {
              staticClass: "caret",
              class: { active: _vm.showMenu },
            }),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.selectedOption.name === undefined
      ? _c(
          "li",
          {
            staticClass: "dropdown-toggle dropdown-toggle-placeholder",
            on: {
              click: function ($event) {
                return _vm.toggleMenu()
              },
            },
          },
          [
            _vm._v("\n      " + _vm._s(_vm.placeholderText) + "\n      "),
            _c("span", { staticClass: "caret" }),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.showMenu
      ? _c(
          "ul",
          { staticClass: "dropdown-menu" },
          _vm._l(_vm.options, function (option, idx) {
            return _c("li", { key: idx }, [
              _c(
                "a",
                {
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function ($event) {
                      return _vm.updateOption(option)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(option.name) +
                      "\n            "
                  ),
                ]
              ),
            ])
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "subscription",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.showSubscriptionModal.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "subscription__bg-1" }),
          _vm._v(" "),
          _c("div", { staticClass: "subscription__bg-2" }),
          _vm._v(" "),
          _vm._m(0),
        ]
      ),
      _vm._v(" "),
      _c(
        "Modal",
        {
          attrs: {
            name: "subscription-modal",
            height: "80%",
            width: 450,
            maxWidth: 500,
            adaptive: true,
          },
        },
        [
          _c("iframe", {
            attrs: {
              width: "100%",
              height: "100%",
              frameborder: "0",
              id: "unisender-subscribe-form",
              src: "https://cp.unisender.com/ru/v5/subscribe-form/view/686stpgd9g1g6bbasqj6s399jeznh8gh1k1rmohe",
            },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "container" }, [
      _c("form", { staticClass: "subscription-form" }, [
        _c("div", { staticClass: "subscription-form__wrapper" }, [
          _c("div", { staticClass: "subscription-form__col1" }, [
            _c("div", { staticClass: "subscription-form__title h3" }, [
              _vm._v("Подписаться на акции и скидки"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "agreement" }, [
              _vm._v(
                "Нажимая на кнопку, я даю согласие на обработку моих персональных данных"
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "subscription-form__col2" }, [
            _c("div", { staticClass: "form-group form-group--subscribe" }, [
              _c("input", {
                staticClass: "form-control",
                attrs: { type: "email", placeholder: "Ваш e-mail" },
              }),
              _vm._v(" "),
              _c("button", {
                staticClass: "email-btn",
                attrs: { type: "submit", value: "" },
              }),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
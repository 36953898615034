import FilterGroup from './FilterGroup.vue';

export default {
	data: function() {
		return {
			value: {}
		}
	},
	props: {
		filters: {
			type: Array
		}
	},
	components: {
		FilterGroup
	},
	methods: {
		doChange: function (e) {
			this.value[e.item] = e.value;
			this.$emit('change');
		}
	}
}



var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "checkboxes" },
    _vm._l(_vm.items, function (field, field_index) {
      return _c("div", { key: field_index, staticClass: "checkbox" }, [
        _c("label", { staticClass: "checkbox__label" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.value,
                expression: "value",
              },
            ],
            attrs: { type: "checkbox", name: "", id: field_index },
            domProps: {
              value: field.value,
              checked: Array.isArray(_vm.value)
                ? _vm._i(_vm.value, field.value) > -1
                : _vm.value,
            },
            on: {
              change: [
                function ($event) {
                  var $$a = _vm.value,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = field.value,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.value = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.value = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.value = $$c
                  }
                },
                _vm.doChange,
              ],
            },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "checkbox__content" }, [
            _c("span", { staticClass: "checkbox__icon" }),
            _vm._v(" "),
            _c("span", { staticClass: "checkbox__text" }, [
              _vm._v(_vm._s(field.title)),
            ]),
          ]),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "quantity" }, [
    _c("a", {
      staticClass: "minus",
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.minus.apply(null, arguments)
        },
      },
    }),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model.number",
          value: _vm.value,
          expression: "value",
          modifiers: { number: true },
        },
      ],
      staticClass: "quantity__input",
      attrs: { type: "text" },
      domProps: { value: _vm.value },
      on: {
        change: _vm.emitChange,
        input: function ($event) {
          if ($event.target.composing) return
          _vm.value = _vm._n($event.target.value)
        },
        blur: function ($event) {
          return _vm.$forceUpdate()
        },
      },
    }),
    _vm._v(" "),
    _c("a", {
      staticClass: "plus",
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.plus.apply(null, arguments)
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
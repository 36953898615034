// import root_items from './json/root.json';
// import level2_items from './json/level2.json';
// import level3_items from './json/level3.json';
//import product_data from './json/product.json';
import Product from '../../product.vue';
export default {
	data: function(){
		return {
			currentLevel1: null,
			currentLevel2: null,
			currentPath: [null, null]
			// level1_items,
			// level2_items,
			// level3_items,
			// product_data: product_data[0]
		}
	},
	props: {
		items: { type: Object },
		isActive: { type: Boolean },
	},
	components: {
		Product
	},
	methods: {
		select: function(level, id) {
			if (level === 1) {
        this.currentLevel1 = id;
        this.currentLevel2 = null;
      }
      if (level === 2) {
        this.currentLevel2 = id;
      }
		},
		mouseover: function(level, id) {
			this.timer = setTimeout(() => { this.select(level, id) }, 200);
		},
		mouseleave: function() {
			clearTimeout(this.timer);
		}
	}
}
